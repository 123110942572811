<template>
  <router-view />
</template>

<script>
const usersLanguage = window.navigator.language.split("-")[0];

export default {
  mounted() {
    if (localStorage.getItem("language") === null) {
      if (
        usersLanguage === "de" ||
        usersLanguage === "es" ||
        usersLanguage === "fr" ||
        usersLanguage === "it"
      ) {
        this.$i18n.locale = usersLanguage;
        localStorage.setItem("language", usersLanguage);
      }
    } else {
      this.$i18n.locale = localStorage.getItem("language");
    }
  },
};
</script>
