export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règlages"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
  "install_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installez TheVoid comme une application."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La croissance spirituelle exige peu. Il suffit d'interrompre la vie quotidienne pendant un court instant et de se connecter avec ce qui est vraiment important. C'est à partir de là que commence le voyage…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette application s'inspire des enseignements de Mario Luis Rodríguez Cobos (Silo) et de sa vision d'une nation humaine universelle"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser comme réflexion quotidienne"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma réflexion quotidienne"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est l’heure de ta réflexion quotidienne"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçois un rappel quotidien de la réflexion choisie."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \nQuestion"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Question"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer ta vie sans te demander"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Qui suis-je ?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers où vais-je ?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer un jour sans te répondre"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui tu es."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers où tu vas."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \nDemande"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Demande"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À un moment donné du jour ou de la nuit, inspire une bouffée d'air et imagine que tu amènes cet air à ton cœur. \n\nAlors, demande avec force pour toi et pour tes êtres les plus chers. Demande avec force pour t'éloigner de tout ce qui t'apporte contradiction ; demande afin que ta vie soit unitive."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le \nChemin"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Chemin"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu crois que ta vie se termine avec la mort,ce que tu penses, sens et fais n’a pas de sens. Tout s’achève dans l’incohérence, dans la désintégration."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu crois que ta vie ne se termine pas avec la mort, ce que tu penses doit coïncider avecce que tu sens et ce que tu fais. Tout doitavancer vers la cohérence, vers l’unité."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu es indifférent à la douleur et à lasouffrance des autres, toute aide que tu demandes ne trouvera pas de justification."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu n’es pas indifférent à la douleur et à la souffrance des autres, tu dois faire en sorteque coïncide ce que tu sens avec ce que tu penses et ce que tu fais pour aider les autres."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprends à traiter les autres de la manière dont tu veux être traité."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprends à dépasser la douleur et la souffrance en toi, dans ton prochain et dans la société humaine."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprends à résister à la violence qu’il y a en toi et au-dehors de toi."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprends à reconnaître les signes du sacréen toi et au-dehors de toi."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer ta vie sans te demander : « Qui suis-je ? »"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer ta vie sans te demander : « Vers où vais-je ? »"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer un jour sans te répondre qui tu es."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer un jour sans te répondre vers où tu vas."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer une grande joie sans remercier en ton intérieur."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne laisse pas passer une grande tristesse sans réclamer en ton intérieur cette joie qui est restée conservée."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N’imagine pas que tu es seul dans ton village, dans ta ville, sur la Terre et dans les mondes infinis."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N’imagine pas que tu es enchaîné à ce temps et à cet espace."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N’imagine pas que dans ta mort s’éternise la solitude."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Principes"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'action valables"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe d'adaptation"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe d’action et de réaction"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de l’action opportune"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de proportion"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de conformité"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe du plaisir"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de l’action immédiate"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de l’action comprise"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de liberté"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de solidarité"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de la négation des opposés"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principe de l’accumulation des actions"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller contre l’évolution des choses ,c’est aller contre soi-même."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand tu forces quelque chose vers un but, tu produis le contraire."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne t’oppose pas à une grande force. Recule jusqu’à ce qu’elle s’affaiblisse ; alors, avance avec résolution.."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les choses sont bien lorsqu’elles marchent ensemble et non isolément."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si pour toi le jour et la nuit, l’été et l’hiver sont bien, tu as dépassé les contradictions."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu poursuis le plaisir, tu t’enchaînes à la souffrance. Mais, tant que tu ne nuis pas à ta  santé, jouis sans inhibition quand l’opportunité s’en présente."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu poursuis un but, tu t’enchaînes. Si tout ce que tu fais, tu l’accomplis comme une fin en soi, tu te libères."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu feras disparaître tes conflits lorsque tu les comprendras dans leur ultime racine et non lorsque tu voudras les résoudre."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque tu portes préjudice aux autres, tu restes enchaîné. Mais si tu ne portes pas préjudice à d’autres, tu peux faire ce que tu veux avec liberté."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque tu traites les autres comme tuveux qu’ils te traitent, tu te libères."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peu importe dans quel camp t’ont placé les événements : ce qui importe, c’est que tu comprennes que tu n’as choisi aucun camp."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les actes contradictoires ou unitifs s’accumulent en toi. Si tu répètes tes actes d’unité intérieure, rien ne pourra plus t’arrêter."])},
  "relax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratiques de relaxation"])},
  "force_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiance de Force"])}
}