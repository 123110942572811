export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä on"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkinen kasvu ei vaadi paljoa. Riittää, että keskeyttää päivittäisen elämän lyhyen hetken ajan ja käsittelee sitä, mikä on todella tärkeää. Ja matka alkaa... "])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämän sovelluksen innoituksena ovat Mario Luis Rodríguez Cobosin opetukset ja hänen visionsa universaalista inhimillisestä kansakunnasta."])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä päivittäisenä mietintönä"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittäinen mietintöni"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On päivittäisen mietintösi aika."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muistutus"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota päivittäinen muistutus valitsemastasi mietinnöstä."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymys"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymys"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna elämäsi mennä kysymättä itseltäsi"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuka olen?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mihin olen menossa?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna päivän mennä vastaamatta itsellesi"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuka olet."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mihin olet menossa."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyyntö"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyyntö"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jonain päivän tai yön hetkenä, hengitä kerran sisään ja kuvittele, että viet tuon ilman sydämeesi.\n\nSitten pyydä voimallisesti itsellesi ja rakastetuimmillesi. Pyydä voimallisesti, että pääsisit pois kaikesta, joka vie sinut ristiriitaan; pyydä, että elämäsi olisi eheää."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tie"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tie"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos uskot, että elämäsi loppuu kuolemaan, niin sillä, mitä ajattelet, tunnet ja teet, ei ole merkitystä. Kaikki päättyy sekavuuteen, hajoamiseen."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos uskot, että elämäsi ei lopu kuolemaan, niin ajatuksesi on liityttävä yhteen tunteittesi ja tekojesi kanssa. Kaiken on edettävä kohti eheyttä, kohti ykseyttä."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos olet välinpitämätön toisten kipua ja kärsimystä kohtaan, niin millekään avunpyynnöillesi ei löydy oikeutusta."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos et ole välinpitämätön toisten kipua ja kärsimystä kohtaan, niin sinun on tuotava tunteesi yhteen sen kanssa, mitä ajattelet ja teet auttaaksesi toisia."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opi kohtelemaan toisia kuten itse tahtoisit tulla kohdelluksi."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opi voittamaan kipu ja kärsimys itsessäsi, lähelläsi olevissa ja inhimillisessä yhteiskunnassa."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opi vastustamaan väkivaltaa, joka on sisälläsi ja ympärilläsi."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opi tunnistamaan pyhän merkit sisälläsi ja ympärilläsi."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna elämäsi mennä kysymättä itseltäsi: «Kuka olen?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna elämäsi mennä kysymättä itseltäsi: «Mihin olen menossa?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna päivän mennä vastaamatta itsellesi, kuka olet."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna päivän mennä vastaamatta itsellesi, mihin olet menossa."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna suuren ilon mennä kiittämättä hiljaa sisälläsi."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä anna suuren surun mennä pyytämättä sisältäsi iloa, joka on siellä tallella."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä kuvittele, että olet yksin kylässäsi, kaupungissasi, Maa-planeetalla ja äärettömien maailmojen parissa."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä kuvittele, että olet kahlittu tähän aikaan ja tähän tilaan."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä kuvittele, että kuolemassasi yksinäisyys ikuistuu."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periaate"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Periaatetta"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pätevään toimintaan"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopeutumisen periaate"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiminnan ja vastavaikutuksen periaate"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopivan toiminnan periaate"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhteellisuuden periaate"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyytyväisyyden periaate"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nautinnon periaate"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välittömän toiminnan periaate"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäistetyn toiminnan periaate"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vapauden periaate"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteishengen periaate"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastakohtaisuuden hylkäämisen periaate"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiminnan kasaantumisen periaate"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastustaa asioiden kehitystä on yhtä kuin vastustaa itseään."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun pakotat jotakin tiettyyn tulokseen, niin tuotat vastakkaista."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä vastusta suurta voimaa. Peräydy kunnes se heikkenee, ja sitten etene päättäväisesti."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiat ovat hyvin, kun ne kulkevat yhdessä, eikä eristettyinä toisistaan."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos päivä ja yö, talvi ja kesä ovat sinusta hyviä, niin olet ylittänyt ristiriidat."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tavoittelet nautintoa, niin kahlitset itsesi kärsimykseen. Mutta, kunhan et vain vahingoita terveyttäsi, nauti estoitta tilanteen tarjotessa."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos pyrit tulokseen, niin kahlitset itsesi. Jos toteutat kaikki toimesi kuin ne jo itsessään olisivat päämäärä, vapautat itsesi."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat ristiriitasi katoamaan, kun ymmärrät niiden perimmäiset syyt, etkä silloin, kun haluat ratkaista ne."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun vahingoitat toisia, pysyt kahleissa. Mutta jos et vahingoita toisia, niin voit vapaasti tehdä mitä tahansa haluat."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun kohtelet toisia kuten tahtoisit heidän kohtelevan itseäsi, vapautat itsesi."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sillä ei ole väliä, mille puolelle tilanteet ovat sinut asettaneet. On tärkeää, että ymmärrät, että et sinä ole valinnut mitään puoluetta."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristiriitaiset tai eheyttävät teot kasautuvat sisääsi. Jos toistat sisäisen eheyden tekosi, niin mikään ei enää pysty sinua pysäyttämään."])}
}