import { createApp } from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import "./assets/css/main.css";
import "./registerServiceWorker";
import Unicon from "vue-unicons";
import VueCookies from "vue-cookies";

import {
  uniBars,
  uniArrowRight,
  uniArrowLeft,
  uniPlay,
  uniAngleRightB,
  uniEllipsisV,
  uniStar,
} from "vue-unicons/dist/icons";

Unicon.add([
  uniBars,
  uniArrowRight,
  uniArrowLeft,
  uniPlay,
  uniAngleRightB,
  uniEllipsisV,
  uniStar,
]);

createApp(App)
  .use(i18n)
  .use(router)
  .use(Unicon)
  .use(VueCookies, { expires: "14d" })
  .mount("#app");
