export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installare"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congedo"])},
  "install_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installare TheVoid come applicazione."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La crescita spirituale non richiede molto. Basta interrompere la vita quotidiana per un breve momento e connettersi con ciò che è veramente importante. Da qui inizia il viaggio…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest'applicazione è ispirata agli insegnamenti di Mario Luis Rodríguez Cobos e alla sua visione di una nazione umana universale"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare come riflessione quotidiana"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la mia riflessione quotidiana"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È tempo per la tua riflessione quotidiana."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promemoria"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverai un promemoria giornaliero della tua riflessione scelta"])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La\nDomanda"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Domanda"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare la tua vita senza chiederti"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi sono?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove vado?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare un solo giorno senza darti una risposta"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi sei."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove vai"])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La\nRichiesta"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Richiesta"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In qualche momento del giorno o della notte, aspira una boccata d'aria e immagina di portare quell'aria al tuo cuore.\n\nQuindi, chiedi con forza per te e per i tuoi cari. Chiedi con forza per allontanarti di tutto ciò che ti porta confusione e contraddizione; chiedi perché la tua vita abbia unità."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il\nCammino"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Cammino"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se credi che la tua vita termini con la morte, ciò che pensi, che senti e che fai non ha senso. Tutto finisce nell’incoerenza, nella disintegrazione."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se credi che la tua vita non termini con la morte, ciò che pensi deve coincidere con ciò che senti e con ciò che fai. Tutto deve dirigersi verso la coerenza, verso l’unità."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei indifferente al dolore e alla sofferenza degli altri, ogni aiuto che tu chieda non troverà giustificazione."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non sei indifferente al dolore e alla sofferenza degli altri, devi fare in modo che ciò che senti coincida con ciò che pensi e con ciò che fai per aiutare gli altri."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impara a trattare gli altri nello stesso modo in cui vuoi essere trattato."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impara a superare il dolore e la sofferenza in te, nel tuo prossimo e nella società umana."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impara a resistere alla violenza che c’è in te e fuori di te."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impara a riconoscere i segni del sacro in te e fuori di te."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare la tua vita senza chiederti: «Chi sono?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare la tua vita senza chiederti: «Dove vado?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare un solo giorno senza darti una risposta su chi sei."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare un solo giorno senza darti una risposta su dove vai."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare una grande allegria senza ringraziare dentro di te."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lasciar passare una grande tristezza senza reclamare dentro di te quell’allegria che vi è rimasta custodita."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non immaginare di essere solo nel tuo villaggio, nella tua città, sulla Terra e negli infiniti mondi."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non immaginare di essere incatenato a questo tempo e a questo spazio."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non immaginare che con la tua morte si perpetui in eterno la solitudine."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Principi"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di azione valida"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di adattamento"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di azione e reazione"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di opportunità dell'azione"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di proporzione"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di conciliazione"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio del piacere"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio dell'azione immediata"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di comprensione dell'azione"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di libertà"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di solidarietà"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di negazione degli opposti"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio di accumulazione delle azioni"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andare contro l'evoluzione delle cose è andare contro se stessi."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando forzi qualcosa verso un fine, produci il contrario."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non opporti a una grande forza. Retrocedi finché essa non si indebolisca; allora, avanza con risolutezza."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cose stanno bene quando vanno insieme, non isolatamente."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se per te stanno bene il giorno e la notte, l'estate e l'inverno, hai superato le contraddizioni."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se persegui il piacere, ti incateni alla sofferenza. Ma se non danneggi la tua salute, godi senza inibizioni quando se ne presenta l'occasione."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se persegui un fine, ti incateni. Se tutto ciò che fai, lo realizzi come se fosse un fine in se stesso, ti liberi."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farai sparire i tuoi conflitti quando li avrai compresi nella loro radice ultima, non quando li vorrai risolvere."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando danneggi gli altri, rimani incatenato. Ma se non danneggi nessuno puoi fare quello che vuoi con libertà."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando tratti gli altri come vuoi essere trattato, ti liberi."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non importa da che parte ti abbiano messo gli eventi, ciò che importa è che tu comprenda di non aver scelto nessuna parte."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli atti contraddittori o unitivi si accumulano in te. Se ripeti i tuoi atti di unità interna, niente ti potrà più fermare."])},
  "relax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratiche di rilassamento"])},
  "force_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienza di Forza"])}
}