export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definições"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O crescimento espiritual não requer muito. Basta parar a nossa vida diária por um breve momento e conectarmo-nos com o que é realmente importante. A partir daqui começa a viagem…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta app é baseada na obra e nos ensinamentos de Mário Luís Rodríguez Cobos e na sua visão de uma nação humana universal"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizar como reflexão diária"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha reflexão diária"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está na hora da tua reflexão diária."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrete"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebe diariamente um lembrete da tua reflexão escolhida."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A \nPergunta"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Pergunta"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar a tua vida sem te perguntares"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem sou?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde vou?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar um dia sem responderes"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem és."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde vais."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O \nPedido"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Pedido"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em algum momento do dia ou da noite, inspira uma lufada de ar e imagina que levas esse ar ao teu coração.\n\nEntão, pede com força por ti e pelos teus seres mais queridos, pede com força para te afastares de tudo aquilo que te gera contradição, pede para que a tua vida tenha unidade."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O \nCaminho"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Caminho"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se crês que a tua vida termina com a morte, o que pensas, sentes e fazes não tem sentido. Tudo acaba na incoerência, na desintegração."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se crês que a tua vida não termina com a morte, deve coincidir o que pensas com o que sentes e com o que fazes. Tudo deve avançar em direção à coerência, em direção à unidade."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se és indiferente à dor e ao sofrimento dos outros, toda a ajuda que peças não encontrará justificação."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se não és indiferente à dor e ao sofrimento dos outros, deves fazer que coincida aquilo que sentes com o que pensas e faças, para ajudar a outros."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a tratar os outros do modo como queres ser tratado."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a superar a dor e o sofrimento em ti, no teu próximo e na sociedade humana."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a resistir à violência que há em ti e fora de ti."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a reconhecer os signos do sagrado em ti e fora de ti."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar a tua vida sem te perguntares: «Quem sou?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar a tua vida sem te perguntares: «Para onde vou?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar um dia sem te responderes quem és."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar um dia sem te responderes para onde vais."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar uma grande alegria sem agradecer ao teu interior."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não deixes passar uma grande tristeza sem reclamar no teu interior aquela alegria que ficou guardada."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não imagines eu estás só na tua região, na tua cidade, na Terra e nos mundos infinitos."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não imagines que estás acorrentado a este tempo e a este espaço."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não imagines que na tua morte se eterniza a solidão."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Princípios"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de ação válida"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Adaptação"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio de Ação e Reação"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Ação Oportuna"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Proporção"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Conformidade"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio do Prazer"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Ação Imediata"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Ação Compreendida"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Liberdade"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Solidariedade"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Negação dos Opostos"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Princípio da Acumulação das Ações"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir contra a evolução das coisas é ir contra si mesmo."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando forças algo para um fim, produzes o contrário."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não te oponhas a uma grande força. Retrocede até que ela se debilite, então avança com resolução."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As coisas estão bem quando andam em conjunto, não isoladamente."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se para ti estão bem o dia e a noite, o verão e o inverno, superaste as contradições."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se persegues o prazer, acorrentas-te ao sofrimento. Mas desde que não prejudiques a tua saúde, goza sem inibição quando a oportunidade se apresente."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se persegues um fim acorrentas-te. Se tudo o que fazes, realizas como se fosse um fim em si mesmo, libertas-te."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farás com que os teus conflitos desapareçam quando os entenderes na sua última raiz, não quando quiseres resolvê-los."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando prejudicas os outros, ficas acorrentado. Mas, se não prejudicas os outros podes fazer quanto queiras com liberdade."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando tratas os outros como queres que te tratem, libertas-te."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não importa em que posição ou bando te colocaram os acontecimentos, o importante é que compreendas que tu não escolheste nenhum bando."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os atos contraditórios ou unitivos acumulam-se em ti. Se repetes os teus atos de unidade interna, já nada poderá deter-te."])}
}