import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/question",
    name: "question",
    component: () =>
      import(/* webpackChunkName: "question" */ "../views/QuestionView.vue"),
  },
  {
    path: "/path",
    name: "path",
    component: () =>
      import(/* webpackChunkName: "path" */ "../views/PathView.vue"),
  },
  {
    path: "/principles",
    name: "principles",
    component: () =>
      import(
        /* webpackChunkName: "principles" */ "../views/PrinciplesView.vue"
      ),
  },
  {
    path: "/principle-info",
    name: "principle-info",
    component: () =>
      import(
        /* webpackChunkName: "principle-info" */ "../views/PrincipleView.vue"
      ),
  },
  {
    path: "/principle/:id",
    name: "principle",
    component: () =>
      import(
        /* webpackChunkName: "principle" */ "../views/PrincipleDetailView.vue"
      ),
  },
  {
    path: "/asking",
    name: "asking",
    component: () =>
      import(/* webpackChunkName: "asking" */ "../views/AskingView.vue"),
  },
  {
    path: "/relax",
    name: "relax",
    component: () =>
      import(/* webpackChunkName: "relax" */ "../views/RelaxView.vue"),
  },
  {
    path: "/player/:id",
    name: "player",
    component: () =>
      import(/* webpackChunkName: "player" */ "../views/PlayerView.vue"),
  },
  {
    path: "/language",
    name: "language",
    component: () =>
      import(/* webpackChunkName: "language" */ "../views/LanguageView.vue"),
  },
  {
    path: "/reminder",
    name: "reminder",
    component: () =>
      import(/* webpackChunkName: "language" */ "../views/ReminderView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
