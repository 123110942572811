export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
  "install_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instala TheVoid como una aplicación."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El crecimiento espiritual no requiere mucho. Basta con interrumpir tu vida diaria por un breve momento y conectar con lo que es realmente importante. Desde aquí comienza el viaje…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación está inspirada en las enseñanzas de Mario Luis Rodríguez Cobos y su visión de una nación humana universal"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar como reflexión diaria"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi reflexión diaria"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hora de tu reflexión diaria."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordatorio"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba un recordatorio diario de tu reflexión elegida."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \nPregunta"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Pregunta"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar tu vida sin preguntarte"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quién soy?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Hacia dónde voy?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar un día sin responderte"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quién eres."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacia dónde vas."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El \nPedido"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Pedido"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En algún momento del día o de la noche, aspira una bocanada de aire e imagina que llevas ese aire a tu corazón. \n\nEntonces, pide con fuerza por ti y por tus seres más queridos. Pide con fuerza por aquello que te trae confusión y contradicción; pide porque tu vida tenga unidad."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El \nCamino"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Camino"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si crees que tu vida termina con la muerte lo que piensas, sientes y haces, no tiene sentido. Todo concluye en la incoherencia, en la desintegración."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si crees que tu vida no termina con la muerte, debe coincidir lo que piensas con lo que sientes y con lo que haces. Todo debe avanzar hacia la coherencia, hacia la unidad."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si eres indiferente al dolor y el sufrimiento de los demás, toda ayuda que pidas no encontrará justificación."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no eres indiferente al dolor y sufrimiento de los demás, debes hacer que coincida lo que sientes con lo que pienses y hagas para ayudar a otros."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a tratar a los demás del modo en que quieres ser tratado."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a superar el dolor y el sufrimiento en ti, en tu prójimo y en la sociedad humana."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a resistir la violencia que hay en ti y fuera de ti."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende a reconocer los signos de lo sagrado en ti y fuera de ti."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar tu vida sin preguntarte: «¿quién soy?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar tu vida sin preguntarte: «¿hacia dónde voy?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar un día sin responderte quién eres."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar un día sin responderte hacia dónde vas."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar una gran alegría sin agradecer en tu interior."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dejes pasar una gran tristeza sin reclamar en tu interior aquella alegría que quedó guardada."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No imagines que estas solo en tu pueblo, en tu ciudad, en la Tierra y en los infinitos mundos."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No imagines que estas encadenado a este tiempo y a este espacio."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No imagines que en tu muerte se eterniza la soledad."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Principios"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de acción válida"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de adaptación"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de acción y reacción"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de la acción oportuna"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de proporción"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de conformidad"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio del placer"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de la acción inmediata"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de la acción comprendida"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de libertad"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de solidaridad"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de la negación de los opuestos"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principio de la acumulación de las acciones"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir contra la evolución de las cosas, es ir contra uno mismo."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando fuerzas algo hacia un fin, produces lo contrario."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No te opongas a una gran fuerza. Retrocede hasta que aquella se debilite, entonces, avanza con resolución."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cosas están bien cuando marchan en conjunto, no aisladamente."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si para ti están bien el día y la noche, el verano y el invierno, has superado las contradicciones."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si persigues el placer te encadenas al sufrimiento. Pero, en tanto no perjudiques tu salud, goza sin inhibición cuando la oportunidad se presente."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si persigues un fin te encadenas. Si todo lo que haces lo realizas como si fuera un fin en sí mismo, te liberas."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harás desaparecer tus conflictos cuando los entiendas en su última raíz, no cuando quieras resolverlos."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando perjudicas a los demás, quedas encadenado. Pero, si no perjudicas a otros, puedes hacer cuanto quieras con libertad."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando tratas a los demás como quieres que te traten, te liberas."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No importa en qué bando te hayan puesto los acontecimientos; lo que importa es que comprendas que tú no has elegido ningún bando."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los actos contradictorios o unitivos se acumulan en ti. Si repites tus actos de unidad interna, ya nada podrá detenerte."])},
  "relax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prácticas de relajación"])},
  "force_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia de Fuerza"])}
}