export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
  "install_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install TheVoid as an app."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiritual growth does not require much. It is enough to interrupt your daily life for a short moment and connect with what is really important. \n\n With this, the journey starts…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app is inspired by the teachings of Mario Luis Rodríguez Cobos (Silo) and his vision of a universal human nation"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select as daily reflection"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My daily reflection"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's time for your daily reflection."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive a reminder of your chosen reflection every day."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The \nQuestion"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Question"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let your life pass by without asking yourself"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who am I?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where am I going?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let a day pass by without giving an answer to yourself about"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who you are."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where you are going."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The \nAsking"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Asking"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In some moment of the day or night inhale a breath of air, and imagine that you bring this air to your heart. \n\nThen, ask with strength for yourself and for your loved ones. Ask with strength to move away from all that brings you contradiction; ask for your life to have unity."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The \nPath"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Path"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you believe that your life will end with death, nothing that you think, feel, or do has any meaning. Everything will end with incoherence and disintegration."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If  you  believe  that  your  life  does  not  end  with  death,  you  must  bring  into agreement what you think with what you feel and what you do. All must advance toward coherence, toward unity."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are indifferent to the pain and suffering of others, none of the help that you ask for will find justification."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not indifferent to the pain and suffering of others, in order to help them you must bring your thoughts, feelings, and actions into agreement."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to treat others in the way that you want to be treated."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to surpass pain and suffering in yourself, in those close to you, and in human society."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to resist the violence that is within you and outside of you."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn to recognize the signs of the sacred within you and around you."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let your life pass by without asking yourself, «Who am I?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let your life pass by without asking yourself, «Where am I going?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let a day pass by without giving an answer to yourself about who you are."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let a day pass by without giving an answer to yourself about where you are going."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let a great joy pass without giving thanks internally."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not let a great sadness pass without calling into your interior for the joy that you have saved there."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not imagine that you are alone in your village, in your city, on the Earth, or among the infinite worlds."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not imagine that you are enchained to this time and this space."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not imagine that in your death loneliness will become eternal."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Principles"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of valid action"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Adaptation"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Action and Reaction"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Well-Timed Action"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Proportion"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Acceptance"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle about Pleasure"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Immediate Action"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Wise Action"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Liberty"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Solidarity"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Avoiding Opposition"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle of Accumulating Actions"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To go against the evolution of things is to against yourself."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you force something toward an end, you produce the contrary."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not oppose a great force. Retreat until it weakens, then advance with resolution."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All is well when things move together, not in isolation."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If day and night, summer and winter are fine with you, you have overcome the contradiction."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you pursue pleasure, you enchain yourself to suffering. But as long as you do not harm your health, enjoy without inhibition when the opportunity presents itself."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you pursue an end, you enchain yourself. If everything you do is realized as though it were an end in itself, you liberate yourself."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will make your conflicts disappear when you understand them in their ultimate root, not when you want to resolve them."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you harm others you remain enchained, but if you do not harm anyone you can freely do whatever you want."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you treat others as you want them to treat you, you liberate yourself."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It does not matter in which faction events have placed you. What matters is that you comprehend that you have not chosen any faction."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contradictory or unifying actions accumulate within you. If you repeat your acts of internal unity, nothing can detain you."])},
  "relax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dis-Tension practices"])},
  "force_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience of Force"])}
}