export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "install_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiere TheVoid als App."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirituelles Wachstum erfordert nicht viel. Es reicht das tägliches Leben für einen kurzen Moment zu unterbrechen und sich mit dem zu verbinden, was wirklich wichtig ist. Von hier aus beginnt die Reise…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese App inspiriert sich an den Lehren von Mario Luis Rodríguez Cobos (Silo) und seiner Vision einer universellen menschlichen Nation"])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als tägliche Reflektion verwenden"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine tägliche Reflektion"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist Zeit für deinen tägliche Reflektion."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte jeden Tag eine Erinnerung zu deiner gewählten Reflektion."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die \nFrage"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass Dein Leben nicht verstreichen, \nohne dich zu fragen"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer bin ich?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohin gehe ich?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass keinen Tag vergehen, \nohne dir zu antworten"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer du bist."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohin du gehst."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die \nBitte"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bitte"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An irgendeinem Augenblick des Tages oder der Nacht atme einmal tief ein und stellle dir vor, dass du diese Luft bis zu deinem Herzen bringst. \n\nSodann bitte mit Kraft für dich und die Menschen, die du liebst. Bitte mit Kraft darum, dass du dich  von alldem fernhältst, was dich in Widerspruch bringt; bitte darum, dass dein Leben einheitlich sei."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der \nWeg"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Weg"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du glaubst, dass dein Leben mit dem Tod endet, so hat das, was du denkst, fühlst und tust, keinen Sinn. Alles endet in der Zusammenhanglosigkeit, in der Auflösung."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du glaubst, dass dein Leben nicht mit dem Tod endet, dann soll das, was du denkst, mit dem übereinstimmen, was du fühlst und tust. Alles soll sich zur Kohärenz, zur Einheit entwickeln."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn dir der Schmerz und das Leiden der anderen gleichgültig sind, werden all deine Bitten um Hilfe unbegründet sein."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn dir der Schmerz und das Leiden der anderen nicht gleichgültig sind, musst du, um anderen zu helfen, dein Fühlen, Denken und Handeln in Einklang bringen."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lerne, die anderen so zu behandeln, wie du selbst behandelt werden möchtest."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lerne, den Schmerz und das Leiden in dir, bei deinem Nächsten und in der menschlichen Gesellschaft zu überwinden."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lerne, der Gewalt zu widerstehen, die in dir und außerhalb von dir ist."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lerne, die Zeichen des Heiligen in dir und außerhalb von dir zu erkennen."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass Dein Leben nicht verstreichen, ohne dich zu fragen: «Wer bin ich?»"])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass Dein Leben nicht verstreichen, ohne dich zu fragen: «Wohin gehe ich?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass keinen Tag vergehen, ohne dir zu antworten, wer du bist."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass keinen Tag vergehen, ohne dir zu antworten, wohin du gehst."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass keine große Freude vorübergehen, ohne in deinem Inneren dafür zu danken."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass keine große Traurigkeit vorübergehen, ohne in deinem Inneren die Freude zurückzuverlangen, die dort bewahrt ist."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stell dir nicht vor, dass du alleine in deinem Dorf, deiner Stadt, auf der Erde und in den unendlichen Welten bist."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stell dir nicht vor, dass du an diese Zeit und an diesen Raum gefesselt bist."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stell dir nicht vor, dass sich bei deinem Tod die Einsamkeit verewigt."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Grundsätze"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der gültigen Handlung"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Anpassung"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Wirkung und Gegenwirkung"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der angemessenen Handlung"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz des Verhältnisses"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Gelassenheit"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz des Geniessens"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der unmittelbaren Handlung"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der verstandenen Handlung"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Freiheit"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Solidarität"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Ablehnung der Gegensätze"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsatz der Ansammlung der Handlungen"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man sich der Entwicklung der Dinge entgegenstellt, geht man gegen sich selbst vor."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du etwas zu erzwingen versuchst, so erzeugst du das Gegenteil."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widersetze dich nicht einer großen Kraft. Weiche zurück, bis sie schwächer wird, dann aber gehe mit Entschlossenheit voran."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist gut, wenn die Dinge zusammen und nicht vereinzelt wirken."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn für dich Tag und Nacht, Sommer und Winter gleich gut sind, hast du die Widersprüche überwunden."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du dem Vergnügen nachjagst, fesselst du dich ans Leiden. Solange du jedoch deiner Gesundheit nicht schadest, genieße unbefangen, wenn sich die Gelegenheit dazu bietet."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du einem Ziel nachjagst, machst du dich unfrei. Wenn du allem, was du tust, so nachgehst, als sei es ein Ziel in sich selbst, befreist du dich."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Konflikte werden dann verschwinden, wenn du sie aus ihrem letzten Grund heraus verstehst, und nicht wenn du sie lösen willst."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du den anderen schadest, bleibst du unfrei. Solange du aber anderen nicht schadest, kannst du in Freiheit tun, was immer du magst."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du andere so behandelst, wie du selbst behandelt werden möchtest, befreist du dich."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt nicht darauf an, auf welche Seite dich die Ereignisse gestellt haben. Wichtig ist für dich, zu begreifen, dass du selbst keine Seite gewählt hast."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die widersprüchlichen oder einheitlichen Handlungen sammeln sich in dir. Wenn du deine Handlungen innerer Einheit wiederholst, kann dich nichts mehr aufhalten."])},
  "relax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktiken zur Entspannung"])},
  "force_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrung der Kraft"])}
}