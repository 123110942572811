export default {
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazásról"])},
  "about_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A spirituális fejlődéshez nem kell sok. Elég naponta egy rövid pillanatra megszakítani az életünket, és kapcsolatba lépni azzal, ami igazán fontos. Az utazás ezzel indul…"])},
  "about_txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt az alkalmazást Mario Luis Rodríguez Cobos (Silo) tanítása és az egyetemes emberi nemzetről alkotott elképzelése ihlette."])},
  "markme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválasztás napi elmélkedésnek"])},
  "my_daily_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napi elmélkedésem"])},
  "notification_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eljött a napi elmélkedésed ideje."])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emlékeztető"])},
  "reminder_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérj emlékeztetőt az általad kiválasztott napi elmélkedéshez."])},
  "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A\nkérdés"])},
  "question_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés"])},
  "question_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon az életed anélkül, hogy megkérdeznéd magadtól"])},
  "question_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki vagyok?"])},
  "question_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hová tartok?"])},
  "question_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon egy napod anélkül, hogy válaszolnál magadnak arra, hogy"])},
  "question_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ki vagy."])},
  "question_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hová tartasz."])},
  "asking_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A\nkérés"])},
  "asking_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérés"])},
  "asking_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bármikor napközben vagy az éjszaka folyamán vegyél egy mély levegőt és képzeld el, hogy ezt a levegőt a szívedbe juttatod.\n\nAzután kérj erősen a magad és a szeretteid számára. Kérd erősen, hogy túllépj azon, ami ellentmondást okoz; kérd, hogy az életed egységes legyen."])},
  "path_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az\nÚt"])},
  "path_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Út"])},
  "path_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha azt hiszed, hogy az életed a halállal véget ér, akkor nincs értelme annak, amit gondolsz, amit érzel és amit cselekszel. Minden ellentmondásban és szétesésben végződik."])},
  "path_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha azt hiszed, hogy az életed nem ér véget a halállal, akkor az, amit gondolsz, meg kell hogy egyezzen azzal, amit érzel és amit cselekszel. Mindennek az egység, az összhang felé kell haladnia."])},
  "path_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha közömbös vagy mások fájdalmával és szenvedésével szemben, nem lesz igazolható semmilyen segítség, amit kérsz."])},
  "path_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha nem vagy közömbös mások fájdalmával és szenvedésével szemben, úgy kell cselekedned, hogy az, amit érzel, megegyezzen azzal, amit gondolsz és teszel, azért, hogy segíts másoknak."])},
  "path_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanulj meg úgy bánni másokkal, ahogy szeretnéd, hogy veled bánjanak."])},
  "path_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanuld meg legyőzni a fájdalmat és a szenvedést magadban, a körülötted lévőkben és az emberi társadalomban."])},
  "path_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanulj meg ellenállni a benned és a körülötted lévő erőszaknak."])},
  "path_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanuld meg felismerni a jeleit annak, ami szent, benned és körülötted."])},
  "path_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon az életed anélkül, hogy megkérdeznéd magadtól: «Ki vagyok?»."])},
  "path_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon az életed anélkül, hogy megkérdeznéd magadtól: «Hová tartok?»"])},
  "path_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon egy napod anélkül, hogy válaszolnál magadnak arra, hogy ki vagy."])},
  "path_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon egy napod anélkül, hogy válaszolnál magadnak arra, hogy hová tartasz."])},
  "path_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon egy nagy öröm anélkül, hogy köszönetet mondanál bensődben."])},
  "path_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne hagyd, hogy elmúljon egy nagy bánat anélkül, hogy előhívnád bensődből azt az örömöt, amit megőriztél."])},
  "path_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne képzeld azt, hogy egyedül vagy a faludban, a városodban, a Földön és a végtelen sok világban."])},
  "path_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne képzeld azt, hogy be vagy zárva ebbe az időbe és ebbe a térbe."])},
  "path_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne képzeld azt, hogy halálodban örökkévaló lesz a magány."])},
  "principle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapelv"])},
  "principles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az érvényes cselekvés"])},
  "principles_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 alapelve"])},
  "principle_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazkodás alapelve"])},
  "principle_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hatás és visszahatás alapelve"])},
  "principle_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az időszerű cselekvés alapelve"])},
  "principle_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az arányosság alapelve"])},
  "principle_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elfogadás alapelve"])},
  "principle_6_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az élvezet alapelve"])},
  "principle_7_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az azonnali cselekvés alapelve"])},
  "principle_8_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megértett cselekvés alapelve"])},
  "principle_9_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szabadság alapelve"])},
  "principle_10_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szolidaritás alapelve"])},
  "principle_11_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ellentétek tagadásának alapelve"])},
  "principle_12_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A halmozott cselekedetek alapelve"])},
  "principle_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dolgok fejlődése ellen fordulni annyi, mint önmagunk ellen fordulni."])},
  "principle_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor erőltetsz valamit egy cél felé, ellenkező eredményt érsz el."])},
  "principle_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne szállj szembe egy nagy erővel; vonulj vissza, amíg meg nem gyengül, és akkor haladj elszántan előre."])},
  "principle_4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dolgok akkor működnek jól, amikor együtt és nem egymástól elszigetelten haladnak."])},
  "principle_5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha neked egyaránt megfelel a nappal és az éjszaka, a nyár és a tél, felülkerekedtél az ellentmondásokon."])},
  "principle_6_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha hajszolod az élvezeteket, a szenvedéshez láncolod magad. De amíg nem okozol kárt az egészségednek, élvezz gátlások nélkül, amikor arra alkalom nyílik."])},
  "principle_7_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha hajszolsz egy célt, leláncolod magad. Ha mindent úgy viszel véghez, mintha önmagában cél lenne, akkor felszabadítod magad."])},
  "principle_8_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkor szünteted meg konfliktusaidat, amikor legmélyebb gyökerükben érted meg őket, és nem amikor meg akarod őket oldani."])},
  "principle_9_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor másoknak ártasz, leláncolod magad, de ha nem ártasz senkinek sem, szabadon tehetsz, amit akarsz."])},
  "principle_10_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor úgy bánsz másokkal, ahogy szeretnéd, hogy veled bánjanak, felszabadítod magad."])},
  "principle_11_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem számít, melyik oldalra állítottak az események. Ami számít, az az, hogy megértsd: te nem választottad egyik oldalt sem."])},
  "principle_12_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ellentmondást valamint az egységet hordozó cselekedetek felhalmozódnak benned. Ha belső egységed cselekedeteit ismétled, akkor semmi sem állíthat meg téged."])}
}