<template>
  <div class="header header-home" v-if="home">
    <h1>{{ title }}</h1>
    <button @click="toggleMenu">
      <unicon name="ellipsis-v" fill="white" />
    </button>
  </div>

  <div class="header header-page" v-else>
    <button @click="goBack"><unicon name="arrow-left" fill="white" /></button>
    <h1>{{ title }}</h1>
    <button
      @click="setMeditation"
      id="favorite"
      :class="{ 'fa-active': isFaActive }"
      v-if="meditation"
    >
      <unicon name="star" fill="white" />
    </button>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    title: String,
    home: String,
    meditation: String,
  },
  data() {
    return {
      isFaActive: false,
    };
  },
  mounted() {
    if (localStorage.getItem("meditation") === this.meditation) {
      this.isFaActive = true;
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    toggleMenu() {
      document.getElementById("sidenav").classList.toggle("open");
    },
    setMeditation() {
      if (this.isFaActive) {
        localStorage.removeItem("meditation");
      } else {
        localStorage.setItem("meditation", this.meditation);
      }
      this.isFaActive = !this.isFaActive;
    },
  },
};
</script>
