<template>
  <AppHeader title="TheVoid" home="1" />
  <div class="container">
    <div class="box" style="margin-bottom: 16px" v-if="deferredPrompt">
      <p>{{ $t("install_info") }}</p>
      <div class="install-actions">
        <button class="btn dismiss" @click="dismiss">
          {{ $t("dismiss") }}
        </button>
        <button class="btn install" @click="install">
          {{ $t("install") }}
        </button>
      </div>
    </div>
    <div id="daily" @click="openMeditation">
      {{ $t("my_daily_reminder") }}
    </div>
    <div class="home-grid">
      <router-link to="/question" class="tile">
        <span>{{ $t("question_title") }}</span>
      </router-link>
      <router-link to="/path" class="tile">
        <span>{{ $t("path_title") }}</span>
      </router-link>
      <router-link to="/principles" class="tile">
        <span>{{ $t("principles_title") }}</span>
      </router-link>
      <router-link to="/asking" class="tile">
        <span>{{ $t("asking_title") }}</span>
      </router-link>
      <router-link to="/relax" class="tile">
        <span>{{ $t("relax_title") }}</span>
      </router-link>
      <router-link to="/player/10" class="tile">
        <span>{{ $t("force_title") }}</span>
      </router-link>
    </div>
  </div>
  <nav id="sidenav">
    <ul>
      <li>
        <router-link to="/about">{{ $t("about") }}</router-link>
      </li>
      <li>
        <router-link to="/reminder">{{ $t("reminder") }}</router-link>
      </li>
      <li>
        <router-link to="/language">{{ $t("language") }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import AppHeader from "@/components/AppHeader";

export default {
  name: "HomeView",
  components: {
    AppHeader,
  },
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      if (this.$cookies.get("add-to-home") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    if (localStorage.getItem("meditation")) {
      document.getElementById("daily").style.display = "block";
    }
  },
  methods: {
    openMeditation() {
      this.$router.push("/" + localStorage.getItem("meditation"));
    },
    async dismiss() {
      this.$cookies.set("add-to-home", null, "14d");
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>

<style scoped>
.home-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-color);
  border-radius: 12px;
  box-sizing: border-box;
  aspect-ratio: 1/ 1;
  font-size: 1.125rem;
  font-weight: bold;
}
.tile span {
  display: block;
  padding: 12px;
}
#daily {
  border: 1px solid orange;
  color: orange;
  cursor: pointer;
  display: none;
  font-weight: bold;
  padding: 20px;
  margin: 0 8px 16px 8px;
}
.install-actions {
  display: flex;
  justify-content: space-around;
}
.install-actions .btn {
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #999999;
  color: white;
  cursor: pointer;
}
.install-actions .install {
  background-color: #3071a9;
}
@media only screen and (min-width: 600px) {
  .tile {
    font-size: 1.5rem;
  }
}
</style>
